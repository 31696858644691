import { Injectable } from '@angular/core';
import { userStatusResponse } from '../../pages/plan/interface/userStatusResponse';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private urlUser = environment.userUrl;

  constructor(private http: HttpClient) { }

  getStatus(userId: number):Observable<userStatusResponse>{
    const url = `${this.urlUser}/status/${userId}`;
    return this.http.get<userStatusResponse>(url);
  }
}
